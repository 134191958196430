import Rails from '@rails/ujs';
// import Turbolinks from 'turbolinks';
import * as ActiveStorage from '@rails/activestorage';
import 'view_components';
import * as $ from 'jquery';
import 'select2';
import 'bootstrap';
import 'stylesheets/application.scss';
import 'fontawesome';
import 'daterangepicker';
import "packs/filters/agent_time_logs";
import "packs/filters/workspace";
import 'theme/theme';
import "chartkick/highcharts"
import "choices.js"
import 'choices.js/public/assets/styles/choices.min.css';
import 'packs/case_tracking';
import 'packs/webform_tlo';
import flatpickr from "flatpickr";
import { createIcons, ClipboardCheck } from 'lucide';

Rails.start();
// Turbolinks.start();
ActiveStorage.start();

document.addEventListener('DOMContentLoaded', function () {
    const casesEditContainer = document.getElementById('casesEdit');
    if (casesEditContainer) {
        import('components/cases_edit');
    }

    const generateAndSend = document.getElementById('generateAndSend');
    if (generateAndSend) {
        import('components/GenerateAndSend');
    }

    const reminders = document.getElementById('js-reminders');
    if (reminders) {
        import('components/Reminders');
    }

    const topSearchBar = document.getElementById('js-topSearchBar');
    if (topSearchBar) {
        import('components/SearchBar');
    }

    const webform = document.getElementById('js-webform');
    if (webform) {
        import('components/Webform/Index');
    }

    $('.js-select2-multiple').select2({
        placeholder: 'Click Dropdown',
        allowClear: true,
    });

    $(".js-select2-single").select2({
        allowClear: true,
        width: "100%",
    });

    $(".js-select2-single.select2-sm").select2({
        allowClear: true,
        width: "resolve",
        containerCssClass : "select2-sm"
    });

    $(document).on('select2:open', () => {
        document.querySelector('.select2-search__field').focus();
    });

    $(document).on('ajax:success', '.js-copy-from-s3', (event) => {
        alert('File Sync Started.');
    });

    $(".daterange-single-picker").each((idx, el) => {
        $(el).daterangepicker({
            opens: "left",
            locale: {
                format: "MMMM DD, YYYY",
                cancelLabel: "Clear"
            },
            singleDatePicker: true,
            autoApply: false,
            alwaysShowCalendars: true,
        })

        $(el).on("cancel.daterangepicker", function() {
            $(this).val("");
        });
    });

    $('.daterange').daterangepicker();

    var changeLogModal = document.getElementById('changeLogModal');
    if (changeLogModal) {
        changeLogModal.addEventListener('show.bs.modal', function (event) {
            // Button that triggered the modal
            var button = event.relatedTarget;
            // Extract info from data-* attributes
            var k = button.getAttribute('data-k');
            var vf = button.getAttribute('data-vf');
            var vl = button.getAttribute('data-vl');
            var created_at = button.getAttribute('data-createdDate');
            var duration = button.getAttribute('data-duration');
            // Send data to erb
            var from = changeLogModal.querySelector('.js-from');
            from.innerHTML = k;
            var vfData = changeLogModal.querySelector('.js-vf');
            vfData.innerHTML = vf;
            var vlData = changeLogModal.querySelector('.js-vl');
            vlData.innerHTML = vl;
            var createdAt = changeLogModal.querySelector('.js-createdAt');
            createdAt.innerHTML = created_at;
            var durationDate = changeLogModal.querySelector('.js-duration');
            durationDate.innerHTML = duration;
        });
    }


    // Toast
    const toasts = document.querySelectorAll('.toast');
    if (Boolean(toasts.length)) {
        toasts.forEach(function(el) {
            new window.Toast(el).show();
        });
    }

    $('#js-toggle-password').on('click', () => {
        var input = $('#user_password')[0];
        if (input.type === 'password') {
            input.type = 'text';
        } else {
            input.type = 'password';
        }
        $('#js-toggle-password').toggleClass('text-dark');
    });

    // Fix issue with TinyMCE inside Bootstrap dialog
    document.addEventListener('focusin', (e) => {
        if (e.target.closest(".tox-tinymce, .tox-tinymce-aux, .moxman-window, .tam-assetmanager-root") !== null) {
            e.stopImmediatePropagation();
        }
    });

    const fp = flatpickr(".flatpickr", { altInput: true });
    const fpWorkspace = flatpickr(".flatpickr-workspace", { altInput: true, dateFormat: "F j, Y" });

    createIcons({ icons: { ClipboardCheck } });
});
