import * as $ from 'jquery';
import moment from "moment";

$(function() {
    const $TLOSearchForm = $("#js-webform-tlo-search-form");
    const $TLOSearchLoader = $("#js-tlo-search-loader");
    const $TLOSearchTimestamp = $("#js-tlo-search-timestamp");
    const $TLODataContainer = $("#js-tlo-data-container");

    if ($TLOSearchForm) {
        $TLOSearchForm.on("submit", function(e){
            $TLOSearchTimestamp.html(moment(new Date()).format("MM/DD/YYYY hh:mm A"))
            $TLODataContainer.addClass("d-none");
            $TLOSearchLoader.removeClass("d-none");
        });
    }
});